exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acceptable-use-policy-tsx": () => import("./../../../src/pages/acceptable-use-policy.tsx" /* webpackChunkName: "component---src-pages-acceptable-use-policy-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-data-processing-agreement-tsx": () => import("./../../../src/pages/data-processing-agreement.tsx" /* webpackChunkName: "component---src-pages-data-processing-agreement-tsx" */),
  "component---src-pages-fair-billing-policy-tsx": () => import("./../../../src/pages/fair-billing-policy.tsx" /* webpackChunkName: "component---src-pages-fair-billing-policy-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/GDPR.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-security-and-compliance-tsx": () => import("./../../../src/pages/security-and-compliance.tsx" /* webpackChunkName: "component---src-pages-security-and-compliance-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-vulnerability-policy-tsx": () => import("./../../../src/pages/vulnerability-policy.tsx" /* webpackChunkName: "component---src-pages-vulnerability-policy-tsx" */)
}

